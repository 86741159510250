import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Loader from "./Components/Common/Loader";
import CommonCss from "./Components/Assets/css/CommonCss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import { GetAppSettingData, GetUserDetails } from "./services/commonService";
import { useDispatch, useSelector } from "react-redux";
import ProposalQuotationPage from "./Components/subModules/ProposalManagement/ProposalQuotationPage";
import { loginItemsSelector } from "./redux/login";
import MaintenanceLayout from "./Components/Screens/MaintenanceLayout";
import { MaintenanceRoute } from "./Components/Common/MaintenanceRoute";
import { appSettingDataSelector } from "./redux/appSetting";
import { PrivateContributorRoute } from "./Components/Common/PrivateRoute";
import soldImagesPage from "./Components/contributers/pages/soldImages";
import SoldImageHistoryPage from "./Components/contributers/pages/SoldImageHistoryPage";
const LoginContributer = lazy(() =>
  import("./Components/contributers/login/LoginContributer")
);
const WelcomePage = lazy(() =>
  import("./Components/contributers/pages/welcomePage")
);
const BankDetailPage = lazy(() =>
  import("./Components/contributers/pages/bankdetailPage")
);
const ImagesPage = lazy(() =>
  import("./Components/contributers/pages/imagesPage")
);
const StarsCollection = lazy(() =>
  import("./Components/Screens/StarContent/StarsCollection")
);
const ContributorSoldImages = lazy(() =>
  import("./Components/contributers/pages/soldImages")
);
const ChangePasswordContributor = lazy(() =>
  import("./Components/contributers/pages/changePasswordPage")
);

const SoldImagePaymentsHistory = lazy(() =>
  import("./Components/contributers/pages/SoldImageHistoryPage")
);
const ContributorPayment = lazy(() =>
  import("./Components/contributers/pages/contributorPaymentPage")
);
const ExclusiveImageSearchList = lazy(() =>
  import("./Components/Screens/ExclusiveImageSearchList")
);

import ReactGA from "react-ga4";
import ApiConfig from "./config/ApiConfig";
import uploadImagesPage from "./Components/contributers/pages/uploadImages";
import browseImagesPage from "./Components/contributers/pages/browseImages";
import folderListPage from "./Components/contributers/pages/folderList";
if (process.env.REACT_APP_ENV == "prod") {
  ReactGA.initialize(ApiConfig.GOOGLE_TAG_MANAGER_KEY);
}

const HomePage = lazy(() => import("./Components/Screens/HomePage"));
const StarsHomePage = lazy(() => import("./Components/Screens/StarsHomePage"));
const SearchTips = lazy(() => import("./Components/Screens/SearchTips"));
const Ordering = lazy(() => import("./Components/Screens/Ordering"));
const Downloading = lazy(() => import("./Components/Screens/Downloading"));
const Licensing = lazy(() => import("./Components/Screens/Licensing"));
const NonExclusiceRight = lazy(() =>
  import("./Components/Screens/NonExclusiceRight")
);
const CompUsageAgreement = lazy(() =>
  import("./Components/Screens/CompUsageAgreement")
);
const EditorialAgreement = lazy(() =>
  import("./Components/Screens/EditorialAgreement")
);
const WishlistHelpTopic = lazy(() =>
  import("./Components/Screens/WishlistHelpTopic")
);
const MyAccountHelpTopic = lazy(() =>
  import("./Components/Screens/MyAccountHelpTopic")
);
const FAQ = lazy(() => import("./Components/Screens/FAQ"));
const Glossary = lazy(() => import("./Components/Screens/Glossary"));
const AboutUs = lazy(() => import("./Components/Screens/AboutUs"));
const SingleCategory = lazy(() =>
  import("./Components/Screens/SingleCategory")
);
const VideoListing = lazy(() => import("./Components/Screens/VideoListing"));
const SimilarImages = lazy(() => import("./Components/Screens/SimilarImages"));
const CommonInvoice = lazy(() => import("./Components/Screens/CommonInvoice"));
const PendingOrderInvoice = lazy(() =>
  import("./Components/Screens/PendingOrderInvoice")
);
const ResetPassword = lazy(() => import("./Components/Screens/ResetPassword"));
const ProposalDetail = lazy(() =>
  import("./Components/Screens/ProposalDetail")
);
const HelpTopics = lazy(() => import("./Components/Common/HelpTopics"));
const TermsOfUse = lazy(() => import("./Components/Screens/TermsOfUse"));
const PrivacyPolicy = lazy(() => import("./Components/Screens/PrivacyPolicy"));
const PrivateRoute = lazy(() => import("./Components/Common/PrivateRoute"));
const ImageResearch = lazy(() => import("./Components/Screens/ImageResearch"));
const Contributor = lazy(() => import("./Components/Screens/Contributor"));
const Testimonial = lazy(() => import("./Components/Screens/Testimonial"));
const Technical = lazy(() => import("./Components/Screens/Technical"));
const AccountProfile = lazy(() =>
  import("./Components/Screens/AccountProfile")
);
const AccountUpgradeImage = lazy(() =>
  import("./Components/Screens/AccountUpgradeImage")
);
const AccountUpgradeSubscriptionList = lazy(() =>
  import("./Components/Screens/AccountUpgradeSubscriptionList")
);
const PrivilegeDownloadHistory = lazy(() =>
  import("./Components/Screens/PrivilegeDownloadHistory")
);
const AccountChangePasswordComponent = lazy(() =>
  import("./Components/Screens/AccountChangePasswordComponent")
);
const AccountOrders = lazy(() => import("./Components/Screens/AccountOrders"));
const AllOrdersData = lazy(() => import("./Components/Screens/AllOrdersData"));
const AccountProposals = lazy(() =>
  import("./Components/Screens/AccountProposals")
);
const Login = lazy(() => import("./Components/Screens/Login"));
const Model = lazy(() => import("./Components/Screens/Models"));
const OtpVerify = lazy(() => import("./Components/Screens/OtpVerify"));
const SignUp = lazy(() => import("./Components/Screens/SignUp"));
const PricingDisplay = lazy(() =>
  import("./Components/Screens/PricingDisplay")
);
const ViewCart = lazy(() => import("./Components/Screens/ViewCart"));
const AddToCartSecond = lazy(() =>
  import("./Components/Screens/AddToCartSecond")
);
const PricingUpgrades = lazy(() =>
  import("./Components/Screens/PricingUpgrades")
);
const AddToCartVideos = lazy(() =>
  import("./Components/Screens/AddToCartVideos")
);
const StarCollectionPricing = lazy(() =>
  import("./Components/Screens/StarContent/StarCollectionPricing")
);
const DownloadHistory = lazy(() =>
  import("./Components/Screens/DownloadHistory")
);
const DeliverOrderImagesPage = lazy(() =>
  import("./Components/Screens/DeliverOrderImages/DeliverOrderImagePage")
);
const DownloadImagesHistory = lazy(() =>
  import("./Components/Screens/DownloadImagesHistory")
);
const VideoBazaar = lazy(() => import("./Components/Screens/VideoBazaar"));
const MoreInfo = lazy(() => import("./Components/Screens/MoreInfo"));
const StarsMoreInfo = lazy(() =>
  import("./Components/Screens/StarContent/StarsMoreInfo")
);
const Pricing = lazy(() => import("./Components/Screens/Pricing"));
const Checkout = lazy(() => import("./Components/Screens/Checkout"));
const CheckoutPayment = lazy(() =>
  import("./Components/Screens/CheckoutPayment")
);
const CheckoutIVS = lazy(() => import("./Components/Screens/CheckoutIVS"));
const CheckoutProposals = lazy(() =>
  import("./Components/Screens/CheckoutProposals")
);
const CheckoutRenewal = lazy(() =>
  import("./Components/Screens/CheckoutRenewal")
);
const PlanBasedPricing = lazy(() =>
  import("./Components/Screens/PlanBasedPricing")
);

const ChangePassword = lazy(() =>
  import("./Components/Screens/ChangePassword")
);
const ContactUs = lazy(() => import("./Components/Screens/ContactUs"));
const AfterSocialLoginForm = lazy(() =>
  import("./Components/Modals/AfterSocialLoginForm")
);
const WishList = lazy(() => import("./Components/Screens/WishList"));
const WishListDetails = lazy(() =>
  import("./Components/Screens/WishListDetails")
);
const EntireShoot = lazy(() => import("./Components/Screens/EntireShoot"));
const ModelRelease = lazy(() => import("./Components/Screens/ModelRelease"));
const StarsEntireShoot = lazy(() =>
  import("./Components/Screens/StarsEntireShoot")
);
const VideoMoreInfo = lazy(() => import("./Components/Screens/VideoMoreInfo"));
const VideoEntireShoot = lazy(() =>
  import("./Components/Screens/VideoEntireShoot")
);
const SameModelImages = lazy(() =>
  import("./Components/Screens/SameModelImages")
);

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { triggerUiRerender } = useSelector(loginItemsSelector);
  const { appSettingData } = useSelector(appSettingDataSelector);
  const [authUser, setAuthUser] = useState(false);
  const [openFreeImageForm, setOpenFreeImageForm] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const navigate = useNavigate();

  const getUserData = async () => {
    await dispatch(GetUserDetails());
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(GetAppSettingData());
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined
    ) {
      getUserData();
    }

    // Code for google tag manager
    // Implementation for GA
    // ReactGA.pageview(location.pathname + location.search);
    // console.log("LOCATION", location)

    // Implementation for GA4
    if (ReactGA.isInitialized && process.env.REACT_APP_ENV == "prod") {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: location.pathname,
      });
    }
    if (location?.pathname !== "/signin" && location?.pathname !== "/signup") {
      const lastLocation = location;
      let lastVisitedUrl = lastLocation?.pathname;
      if (String(lastLocation?.search?.length) > 0) {
        lastVisitedUrl = lastVisitedUrl + lastLocation?.search;
      }
      localStorage.setItem("lastVisitedUrl", lastVisitedUrl);
    }
  }, [location]);

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("wishlistImages");
    localStorage.removeItem("lastSearchKeyword");
    setAuthUser(false);
    window.location.href = "/";
  };
  const handleCloseUserDetailsForm = () => {
    setOpenFreeImageForm(false);
  };
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user"));
  useEffect(() => {
    if (token && user) {
      const decodedJwt = jwt_decode(token);
      setAuthUser(true);
      const expirationTime = decodedJwt?.exp * 1000;
      if (expirationTime < Date.now()) {
        logoutUser();
      }
    }
  }, [location, token, user, triggerUiRerender]);
  useEffect(() => {
    if (token && user) {
      if (
        user?.company_name === null ||
        user?.country === null ||
        user?.email === null ||
        user?.first_name === null ||
        user?.last_name === null ||
        user?.mobile === null ||
        user?.job_description === null ||
        user?.state === null
      ) {
        setOpenFreeImageForm(true);
      }
    }
  }, [location, token, user]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV == "prod") {
      if (
        appSettingData?.items?.features?.website_status[0]?.key_value == "1"
      ) {
        setIsMaintenance(true);
      } else {
        setIsMaintenance(false);
        if (isMaintenance) {
          navigate("/");
        }
      }
    } else {
      setIsMaintenance(false);
      if (isMaintenance) {
        navigate("/");
      }
    }
  }, [appSettingData]);

  return (
    <Suspense fallback={<Loader />}>
      {/* {openFreeImageForm === true && (
        <AfterSocialLoginForm
          open={openFreeImageForm}
          onClose={handleCloseUserDetailsForm}
        />
      )} */}
      <ToastContainer />
      <Routes>
        <Route element={<MaintenanceRoute isMaintenance={isMaintenance} />}>
          {/* without login  root */}
          <Route path="/" element={<HomePage authUser={authUser} />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/aboutus" element={<AboutUs authUser={authUser} />} />
          <Route
            path="/searchtips"
            element={<SearchTips authUser={authUser} />}
          />
            <Route path="/Models" element={<Model />} />
          
          <Route path="/ordering" element={<Ordering authUser={authUser} />} />
          <Route
            path="/downloading"
            element={<Downloading authUser={authUser} />}
          />
          <Route
            path="/licensing"
            element={<Licensing authUser={authUser} />}
          />
          <Route
            path="/wishlisthelp"
            element={<WishlistHelpTopic authUser={authUser} />}
          />
          <Route
            path="/privacypolicy"
            element={<PrivacyPolicy authUser={authUser} />}
          />
          <Route path="/glossary" element={<Glossary authUser={authUser} />} />
          <Route
            path="/myaccounthelp"
            element={<MyAccountHelpTopic authUser={authUser} />}
          />
          <Route
            path="/termsofuse"
            element={<TermsOfUse authUser={authUser} />}
          />
          <Route path="/faq" element={<FAQ authUser={authUser} />} />
          <Route
            path="/mashnonexeclusiverightagreement"
            element={<NonExclusiceRight authUser={authUser} />}
          />
          <Route
            path="/editorial"
            element={<EditorialAgreement authUser={authUser} />}
          />
          <Route
            path="/compusagesagreement"
            element={<CompUsageAgreement authUser={authUser} />}
          />
          <Route
            path="/imageresearch"
            element={<ImageResearch authUser={authUser} />}
          />
          {/* <Route
            path="/contributor"
            element={<Contributor authUser={authUser} />}
          /> */}

          <Route path="/contributor">
            <Route index={true} element={<Contributor authUser={authUser} />} />
            <Route path="signin" element={<LoginContributer />} />
            <Route
              path="dashboard"
              element={<PrivateContributorRoute Children={WelcomePage} />}
            />
            <Route
              path="bankDetail"
              element={<PrivateContributorRoute Children={BankDetailPage} />}
            />
            <Route
              path="images"
              element={<PrivateContributorRoute Children={ImagesPage} />}
            />
            <Route
              path="soldImages"
              element={<PrivateContributorRoute Children={soldImagesPage} />}
            />
            <Route
              path="uploadImages"
              element={<PrivateContributorRoute Children={uploadImagesPage} />}
            />
            <Route
              path="folderList"
              element={<PrivateContributorRoute Children={folderListPage} />}
            />
            <Route
              path="browseImages"
              element={<PrivateContributorRoute Children={browseImagesPage} />}
            />
            <Route
              path="payment/:id"
              element={<PrivateContributorRoute Children={SoldImagePaymentsHistory} />}
            />
            <Route
              path="changePassword"
              element={
                <PrivateContributorRoute Children={ChangePasswordContributor} />
              }
            />
            {/* <Route path="/contributor" element={<Contributor authUser={authUser} />}/> */}

            <Route
              path="payments"
              element={
                <PrivateContributorRoute Children={ContributorPayment} />
              }
            />
          </Route>
          <Route
            path="/testimonial"
            element={<Testimonial authUser={authUser} />}
          />
          <Route
            path="/technical"
            element={<Technical authUser={authUser} />}
          />
          <Route path="/quotation" element={<ProposalQuotationPage />} />
          <Route path="/deliverImages" element={<DeliverOrderImagesPage />} />
          <Route path="/pricing" element={<Pricing authUser={authUser} />} />
          <Route
            path="/pricingdisplay"
            element={<PricingDisplay authUser={authUser} />}
          />
          <Route path="/verify" element={<OtpVerify />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/video">
            <Route index={true} element={<Navigate to={"/"} />} />
            <Route
              path="search/:searchKeyword"
              element={<VideoListing authUser={authUser} />}
            />
            <Route
              path="videomoreinfo/:videoId"
              element={<VideoMoreInfo authUser={authUser} />}
            />
          </Route>
          <Route path="/stars">
            <Route
              index={true}
              element={<StarsHomePage authUser={authUser} />}
            />
            <Route
              path="search/:searchKeyword"
              element={<StarsCollection authUser={authUser} />}
            />
            <Route
              path="starsmoreinfo/:imageId"
              element={<StarsMoreInfo authUser={authUser} />}
            />
            <Route
              path="starsentireshoot/:imageId"
              element={<StarsEntireShoot authUser={authUser} />}
            />
            <Route
              path="starpricing/:imageId"
              element={<PrivateRoute Children={StarCollectionPricing} />}
            />
          </Route>
          <Route
            path="/entireshoot/:imageId"
            element={<EntireShoot authUser={authUser} />}
          />

          <Route
            path="/videoentireshoot/:videoId"
            element={<VideoEntireShoot authUser={authUser} />}
          />
          <Route
            path="/samemodelimages/:imageId"
            element={<SameModelImages authUser={authUser} />}
          />
          {/* <Route
            path="/modelRelease/:releaseId"
            element={<ModelRelease authUser={authUser} />}
          /> */}
          <Route
            path="/search/:searchKeyword"
            element={<SingleCategory authUser={authUser} />}
          />
          <Route
            path="/exclusiveSearchResults/:searchKeyword"
            element={<ExclusiveImageSearchList authUser={authUser} />}
          />
          <Route
            path="/advancesearchresultViewsimilar/:similarImageName"
            element={<SimilarImages authUser={authUser} />}
          />
          {/* <Route path="/search/:searchKeyword">
            <Route index element={<SingleCategory authUser={authUser} />} />
            <Route path="/search/:searchKeyword/:keywords" element={<SingleCategory authUser={authUser} />}/>
          </Route> */}
          <Route
            path="/contactus"
            element={<ContactUs authUser={authUser} />}
          />
          <Route
            path="/moreinfo/:imageId"
            element={<MoreInfo authUser={authUser} />}
          />
          {/* <Route path="/forgotPassword" element={<ForgotPassword />} /> */}
          {/* <Route path="404" element={<Notfound />} /> */}
          <Route path="*" element={<Navigate to={`/`} replace />} />
          {/* with login  root */}
          <Route
            path="/viewcart"
            element={<PrivateRoute Children={ViewCart} authUser={authUser} />}
          />
          <Route
            path="/addtocart/:imageId"
            element={<PrivateRoute Children={AddToCartSecond} />}
          />
          <Route
            path="/pricingupgrade/:imageId"
            element={<PrivateRoute Children={PricingUpgrades} />}
          />
          <Route
            path="/addtocartvideo/:videoId"
            element={<PrivateRoute Children={AddToCartVideos} />}
          />
          <Route
            path="/checkout/:imageId"
            element={<PrivateRoute Children={Checkout} />}
          />
          <Route
            path="/checkoutpayment/:orderId"
            element={<PrivateRoute Children={CheckoutPayment} />}
          />
          <Route
            path="/checkoutimagevideoPlan/:ivsPlanId"
            element={<PrivateRoute Children={CheckoutIVS} />}
          />
          {/* <Route
            path="/checkoutproposals/:proposalId"
            element={<PrivateRoute Children={CheckoutProposals} />}
          /> */}
          <Route
            path="/checkoutimagesvideosrenew"
            element={<PrivateRoute Children={CheckoutRenewal} />}
          />
          <Route
            path="/imagesvideosrenew"
            element={<PrivateRoute Children={PlanBasedPricing} />}
          />
          <Route
            path="/invoice/:orderNumber"
            element={<PrivateRoute Children={CommonInvoice} />}
          />
          {/* <Route path="/quotation/:orderNumber" element={<PrivateRoute Children={PendingOrderInvoice} />}
          /> */}
          <Route
            path="/pendingorderdetail/:orderNumber"
            element={<PrivateRoute Children={PendingOrderInvoice} />}
          />
          {/* <Route path="/proposaldetail/:proposalId" element={<PrivateRoute Children={ProposalDetail} />}
          /> */}
          {/* <Route path="/addtocarttwo" element={<PrivateRoute Children={AddToCartSecond} />}
          /> */}
          <Route
            path="/account"
            element={<PrivateRoute Children={AccountProfile} />}
          />
          <Route
            path="/upgradeimages"
            element={<PrivateRoute Children={AccountUpgradeImage} />}
          />
          <Route
            path="/UpgradeimagesvideosImages"
            element={<PrivateRoute Children={AccountUpgradeSubscriptionList} />}
          />
          <Route
            path="/privilegehistory"
            element={<PrivateRoute Children={PrivilegeDownloadHistory} />}
          />
          {/* <Route
            path="/ShowAllProposal"
            element={<PrivateRoute Children={AccountProposals} />}
          /> */}
          <Route
            path="/listimagesvideosubscription/:orderId"
            element={<PrivateRoute Children={DownloadHistory} />}
          />
          <Route
            path="/downloadimageslist/:orderId"
            element={<PrivateRoute Children={DownloadImagesHistory} />}
          />
          <Route
            path="/changepassword"
            element={<PrivateRoute Children={AccountChangePasswordComponent} />}
          />
          <Route path="/recoverpassword" element={<ResetPassword />} />
          <Route
            path="/orders"
            element={<PrivateRoute Children={AccountOrders} />}
          />
          <Route
            path="/allconfirmorder"
            element={<PrivateRoute Children={AllOrdersData} />}
          />
          <Route
            path="/allpendingorder"
            element={<PrivateRoute Children={AllOrdersData} />}
          />
          <Route
            path="/alloutstandingorder"
            element={<PrivateRoute Children={AllOrdersData} />}
          />
          <Route
            path="/allivsorder"
            element={<PrivateRoute Children={AllOrdersData} />}
          />
          <Route
            path="/wishlist"
            element={<PrivateRoute Children={WishList} />}
          />
          <Route
            path="/wishlistdetails"
            element={<PrivateRoute Children={WishListDetails} />}
          />
        </Route>
        <Route path="maintenance" element={<MaintenanceLayout />} />
      </Routes>
      <CommonCss />
    </Suspense>
  );
};

export default AppRoutes;
